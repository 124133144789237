<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container
            grid-list-xl
            fluid
            v-else
        >
            <v-row>
                <v-overlay :value="overlay">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            >
                            </v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{ fraseOverlay }}</p>
                    </div>
                </v-overlay>
                <v-col
                    cols="12"
                    lg="12"
                >
                    <data-table
                        ref="tableRepVacaciones"
                        :tableName="'Historico Vacaciones'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="true"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <template slot="actionButtonsLeft">
                            <v-btn
                                dark
                                class="btnAcciones mr-3"
                                elevation="0"
                                @click="openLayout"
                            >
                                Descargar
                            </v-btn>
                        </template>
                        <template slot="filters">
                            <v-row>
                                <v-col v-if="rol === 'root'"  cols="12" xs="12" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        outlined
                                        v-model="advancedSearch.cliente"></v-autocomplete>
                                </v-col>
                                <v-col v-if="rol==='root' || rol ==='admin'"  cols="12" xs="12" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        v-model="advancedSearch.empresa"
                                        label="Empresa"
                                        outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" lg="6" xl="6" class="py-0" v-if="rol==='root' || rol ==='admin' || rol =='admin-empresa'">
                                    <v-autocomplete
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        v-model="advancedSearch.sucursal"
                                        label="Sucursal"
                                        outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        v-model="advancedSearch.departamento"
                                        label="Departamento"
                                        outlined></v-autocomplete>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" class="py-0" >
                                    <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="advancedSearch.nombre"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <tbody
                            slot="body"
                            slot-scope="{ data }"
                        >
                            <tr
                                :key="item.id"
                                v-for="item in data"
                            >
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre_completo }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal d-flex justify-center">
                                        {{ dateFormat(item.fecha_ingreso) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="tblPrincipal d-flex justify-center">
                                        {{ item.dias_vacaciones }}
                                    </div>
                                </td>
                                <td>
                                    <div class="tblPrincipal d-flex justify-center">
                                        {{ item.vacaciones_fiscales }}
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="openPrevisualizar(item)"
                                                >
                                                    <v-icon
                                                        v-text="'$visibility_outline'"
                                                        class="iconoDelgadoBoton"
                                                    ></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Vista previa</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
            <v-dialog
                v-model="dialogPrevisualizar"
                persistent
                width="750px"
            >
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-space-between">
                        <div></div>
                        <h2>{{ titulo_modal }}</h2>
                        <v-btn
                            @click="cerrarModal()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar modal-pull-right"
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                    </v-card-title>
                    <div
                        v-if="!procesoFinalizado"
                        class="d-flex justify-center align-center pb-4"
                        style="width: 100%; height: 100%"
                    >
                        <div>
                            <v-progress-circular
                                indeterminate
                                :size="100"
                                :width="7"
                                color="blue"
                            ></v-progress-circular>
                        </div>
                    </div>
                    <div v-else>
                        <v-card-text class="pa-0 mt-4">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                        class="d-flex"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :items="tipos_vacaciones"
                                            item-text="nombre"
                                            item-value="valor"
                                            label="Tipo de vacaciones"
                                            persistent-hint
                                            v-model="tipo_value"
                                            no-data-text="Datos no disponibles"
                                        />

                                        <v-btn
                                            class="btnGuardar elevation-0 mt-2"
                                            @click="previsualizar()"
                                            >Buscar</v-btn>

                                    </v-col>
                                    <v-col
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        xl="12"
                                        class="pt-0 contenido"
                                    >
                                        <table class="tbl-modal">
                                            <thead class="tbl-header-modal">
                                                <tr>
                                                    <th class="firstth">Fecha de registro</th>
                                                    <th>Concepto</th>
                                                    <th class="text-center">Días</th>
                                                    <th>Fecha Inicial</th>
                                                    <th>Fecha Final</th>
                                                    <th class="lastth">Remanente</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="infoDetallado.length == 0">
                                                    <td
                                                        class="emptyTableModal"
                                                        colspan="11"
                                                    >
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-else
                                                    v-for="item in infoDetallado"
                                                >
                                                    <td
                                                        class="px-4 py-2"
                                                        style="min-width: 140px"
                                                    >
                                                        <span>{{ dateFormat(item.fecha) }}</span>
                                                    </td>
                                                    <td class="px-4 py-2" style="min-width: 150px">
                                                        <span>{{ item.concepto }}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span>{{ item.dias }}</span>
                                                    </td>
                                                    <td
                                                        class="px-4 py-2"
                                                        style="min-width: 140px"
                                                    >
                                                        <span>{{
                                                            item.fecha_inicio == "NA"
                                                                ? "N/A"
                                                                : dateFormat(item.fecha_inicio)
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        class="px-4 py-2"
                                                        style="min-width: 140px"
                                                    >
                                                        <span>{{
                                                            item.fecha_fin == "NA"
                                                                ? "N/A"
                                                                : dateFormat(item.fecha_fin)
                                                        }}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span>{{ item.disponibles }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="py-4 d-flex justify-end">
                            <v-btn
                                :disabled="infoDetallado.length == 0 ? true : false"
                                class="btnGuardar elevation-0"
                                @click="descargarReporte('detallado')"
                                :loading="isLoading"
                                >Descargar</v-btn
                            >
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogLayout"
                persistent
                width="750px"
            >
                <v-card>
                    <ValidationObserver ref="formLayout" v-slot="{invalid}">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h3 class="titleModal_700_18">{{ titulo_modal }}</h3>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Cliente"
                                                persistent-hint
                                                v-model="cliente_value"
                                                no-data-text="Datos no disponibles"
                                            />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Empresa"
                                                persistent-hint
                                                v-model="empresa_value"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="tipo" rules="required">
                                            <v-autocomplete
                                                outlined
                                                :items="tipos_vacaciones"
                                                item-text="nombre"
                                                item-value="valor"
                                                label="Tipo de vacaciones"
                                                persistent-hint
                                                v-model="tipo_value"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="tipo reporte" rules="required">
                                            <v-autocomplete
                                                outlined
                                                :items="tiposReporte"
                                                item-text="nombre"
                                                item-value="clave"
                                                label="Tipo de reporte"
                                                persistent-hint
                                                v-model="tipo_reporte_value"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_inicio"
                                            label="Fecha inicial"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />    
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_fin"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFin"
                                        />    
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModal()">Cerrar</button>
                            <v-btn class="btnGuardar elevation-0" :disabled="invalid" @click="validarLayout" :loading="isLoading">Descargar</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
    import Datatable from "@/components/datatable/Datatable.vue";
    import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
    import clientesApi from "@/api/clientes";
    import empresasApi from "@/api/empresas";
    import sucursalesApi from '@/api/sucursales'
    import departamentosApi from '@/api/departamentos'
    import empleadosApi from '@/api/empleados'
    import Notify from "@/plugins/notify";

    export default {
        components: {
            "data-table": Datatable,
            VuetifyDatePicker,
        },
        data() {
            return {
                url: "empleados/find",
                datosLogin: null,
                rol: null,
                dialogPrevisualizar: false,
                dialogLayout: false,
                procesoFinalizado: false,
                titulo_modal: null,
                empleado_value: null,
                isLoading: false,
                tipo_value: "",
                empresa_value: null,
                cliente_value: null,
                clientes: [],
                empresas: [],
                sucursales: [],
                departamentos: [],
                overlay: false,
                fraseOverlay: "Descargando",
                columns: [
                    {
                        label: "Empleado",
                        name: "empleado",
                        filterable: false,
                    },
                    {
                        label: "Ingreso",
                        name: "ingreso",
                        filterable: false,
                        align: "center",
                    },
                    {
                        label: "Disponibles",
                        name: "disponibilidad",
                        filterable: false,
                        align: "center",
                    },
                    {
                        label: "Fiscales",
                        name: "fiscales",
                        filterable: false,
                        align: "center",
                    },
                    {
                        label: "Opciones",
                        name: "opciones",
                        filterable: false,
                        align: "center",
                    },
                ],
                filters: {
                    rol_name: null,
                    usuario_id: null,
                    activo: true,
                    paginate: true,
                    cliente_id: null,
                    empresa_id: null,
                    sucursal_id: null,
                    departamento_id: null,
                    nombre_completo: null,
                    order_by: "clave",
                },
                advancedSearch:{
                    cliente: null,
                    empresa: null,
                    sucursal: null,
                    departamento: null,
                    nombre: '',
                },
                infoDetallado: [],
                fecha_fin: null,
                fecha_inicio: null,
                tipos_vacaciones: [
                    {nombre: 'Reales', valor: 'reales'},
                    {nombre: 'Fiscales', valor: 'fiscales'},
                ],
                tiposReporte: [
                    {nombre: 'Consolidado', clave: 'consolidado'},
                    {nombre: 'Detallado Masivo', clave: 'detallado_masivo'},
                ],
                tipo_reporte_value: null
            };
        },
        watch:{
            cliente_value: function(newVal) {
                if(newVal != null){
                    this.getEmpresas(newVal)
                }
            },
            'advancedSearch.cliente': function(newVal) {
                if(newVal != null){
                    this.getEmpresas(newVal)
                }
            },
            'advancedSearch.empresa': function(newVal) {
                if(newVal != null){
                    this.getSucursales(newVal)
                }
            },
            'advancedSearch.sucursal': function(newVal) {
                if(newVal != null){
                    this.getDepartamentos(newVal)
                }
            },

        },
        methods: {
            listar() {
                this.datosLogin = this.$session.get("usuario");
                this.rol = this.datosLogin.rol.name;

                if (this.rol == "admin") {
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id;

                } else if (this.rol == "admin-empresa") {
                    this.filters.cliente_id = this.cliente_value = this.datosLogin.cliente_id;
                    this.filters.empresa_id = this.empresa_value = this.datosLogin.empresa_id;
                } else if (this.rol == "admin-sucursal") {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.filters.empresa_id = this.datosLogin.empresa_id;
                    this.filters.sucursal_id = this.datosLogin.sucursal_id;
                } else {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.filters.empresa_id = this.datosLogin.empresa_id;
                    this.filters.sucursal_id = this.datosLogin.sucursal_id;
                    this.filters.departamento_id = this.datosLogin.departamento_id;
                }

                this.filters.rol_name = this.datosLogin.rol.name;
                this.filters.usuario_id = this.datosLogin.id;
            },
            loadModalData(){
                this.advancedSearch = {
                    cliente: null,
                    empresa: null,
                    sucursal: null,
                    departamento: null,
                    nombre: '',
                }

                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.getEmpresas(this.cliente_value)
                    this.advancedSearch.cliente = this.cliente_value
                }else if(this.rol == 'admin-empresa'){
                    this.getSucursales(this.empresa_value)
                    this.advancedSearch.empresa = this.empresa_value
                }
            },
            setFilters(){
                this.filters.cliente_id = this.advancedSearch.cliente
                this.filters.empresa_id = this.advancedSearch.empresa
                this.filters.sucursal_id = this.advancedSearch.sucursal
                this.filters.departamento_id = this.advancedSearch.departamento

                if(this.advancedSearch.nombre != null && this.advancedSearch.nombre != undefined && this.advancedSearch.nombre != ""){
                    this.filters.nombre_completo = this.advancedSearch.nombre;
                }
                //console.log(this.filters);
                this.$refs.tableRepVacaciones.getData()    
            },
            dateFormat(fecha, tipo) {
                if (!fecha) return;
                let f = fecha.split(" ");
                //console.log(f);
                let fecha2 = f[0].split("-");
                if (tipo == "modal") {
                    return `${fecha2[2]}/${fecha2[1]}/${fecha2[0]}`;
                } else {
                    return `${fecha2[2]} / ${fecha2[1]} / ${fecha2[0]}`;
                }
            },

            openPrevisualizar(item){
                this.dialogPrevisualizar = true
                this.titulo_modal = item.nombre_completo
                this.empleado_value = item.id
                this.empresa_value = item.empresa_id

                this.tipo_value = this.tipos_vacaciones[0].valor

                this.previsualizar()

            },

            previsualizar() {

                this.procesoFinalizado = false
                
                let params = {
                    empleado_id: this.empleado_value,
                    tipo_vacaciones: this.tipo_value,
                    tipo_reporte: "detallado",
                    empresa_id: this.empresa_value,
                    tipo_archivo: "json",
                };

                //console.log(params);
                empleadosApi
                    .historicoVacaciones(params)
                    .then((resp) => {
                        this.infoDetallado = resp.data;
                        //console.log('dentro del then',this.infoDetallado);
                        this.procesoFinalizado = true;
                    })
                    .catch((err) => {
                        //this.cerrarModal()
                        this.infoDetallado = []
                        this.procesoFinalizado = true
                        console.log('Hubo un error');
                        //console.log(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error)
                        }else{
                            let error = Object.values(err.response.data.error)
                            let leyenda = ``

                            for (var i = 0; i < error.length; i++){
                                leyenda += `*` + error[i] + `\n`
                            }

                            Notify.ErrorToast(leyenda)
                        }
                    });
            },

            openLayout(){
                this.dialogLayout = true
                this.titulo_modal = "Descargar reporte consolidado"

                if(this.rol == 'root'){
                    this.getClientes()
                }
                else if(this.rol == 'admin'){
                    this.getEmpresas(this.cliente_value)
                }

            },

            validarLayout(){
                this.$refs.formLayout.validate().then(success => {
                    if(success){
                        this.descargarReporte(this.tipo_reporte_value)
                    }

                    return
                })
            },

            descargarReporte(tipo_reporte) {
                let url = "reportes/historico-vacaciones";
                //this.overlay = true;
                this.isLoading = true
                let today = new Date();
                const FileDownload = require("js-file-download");
                const axios = require("axios");
                let params = {};

                var nombre =
                    "Reporte " +
                    tipo_reporte +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    "_" +
                    today.getHours() +
                    "," +
                    today.getMinutes() +
                    "," +
                    today.getSeconds() +
                    ".xlsx";

                if (tipo_reporte == "detallado") {
                    params = {
                        empleado_id: this.empleado_value,
                        tipo_vacaciones: this.tipo_value,
                        tipo_reporte: "detallado",
                        empresa_id: this.empresa_value,
                        tipo_archivo: "xlsx",
                    };
                }
                else{
                    params = {
                        tipo_vacaciones: this.tipo_value,
                        tipo_reporte,
                        empresa_id: this.empresa_value,
                        min_fecha: this.fecha_inicio,
                        max_fecha: this.fecha_fin,
                        tipo_archivo: "xlsx", 
                    }
                }

                try {
                    axios({
                        method: "post",
                        url: url,
                        responseType: "blob",
                        data: params,
                    })
                        .then((response) => {
                            
                            if (response.status == 200){
                                FileDownload(response.data, nombre);
                                this.isLoading = false  
                            } 
                            else
                                Notify.ErrorToast(
                                    "Algo ha salido mal, intenta de nuevo o consulta a soporte técnico."
                                );
                        })
                        .catch(async (err) => {
                            this.isLoading = false;
                            console.log(err);
                            /* let errorString = err.response.data;
                            if (
                                err.request.responseType === "blob" &&
                                err.response.data instanceof Blob &&
                                err.response.data.type &&
                                err.response.data.type.toLowerCase().indexOf("json") != -1
                            ) {
                                errorString = JSON.parse(await err.response.data.text());
                                console.log(errorString);

                                if (typeof errorString.error === "string") {
                                    Notify.ErrorToast(errorString.error);
                                } else {
                                    let error = Object.values(errorString.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            } */
                        });
                } catch (error) {
                    self.overlay = false;
                    this.isLoading = false
                }
            },

            async getClientes(){
                
                let param = { activo: true, paginate: false };
                await clientesApi.getClientes(param).then(response => {
                    this.clientes = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los clientes");
                });
            },   

            getEmpresas(cliente){
                if(cliente == null){
                    return;
                }

                let params = { activo: true, paginate: false, cliente_id: cliente };
                empresasApi.getEmpresas(params).then(resp=>{
                    this.empresas = resp.data
                    //console.log(resp.data);
                }).catch(err =>{
                    console.log('Ocurrio un error al cargar las empresas', err);
                })
            },
            getSucursales(empresa){
                if(empresa == null){
                    return;
                }

                let params = { activo: true, paginate: false, empresa_id: empresa };
                sucursalesApi.getSucursales(params).then(resp=>{
                    this.sucursales = resp.data
                    //console.log(resp.data);
                }).catch(err =>{
                    console.log('Ocurrio un error al cargar las sucursales', err);
                })
            },
            getDepartamentos(sucursal){
                if(sucursal == null){
                    return;
                }

                let params = { activo: true, paginate: false, sucursal_id: sucursal };
                departamentosApi.getDepartamentos(params).then(resp=>{
                    this.departamentos = resp.data
                    //console.log(resp.data);
                }).catch(err =>{
                    console.log('Ocurrio un error al cargar los departamentos', err);
                })
            },

            cerrarModal() {
                this.dialogPrevisualizar = false;
                this.dialogLayout = false
                this.resetValues();
            },

            resetValues() {
                this.procesoFinalizado = false;
                this.infoDetallado = null;
                this.empleado_value = null
                this.tipo_value = ""
                if(this.rol == 'root'){
                    this.cliente_value = null
                    this.empresa_value = null
                }else if(this.rol == 'admin'){
                    this.empresa_value = null
                }
                this.fecha_fin = null
                this.fecha_inicio = null
                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFin != undefined || this.$refs.FechaFin != null){
                    this.$refs.FechaFin.resetDate();
                }
                if(this.$refs.formLayout){
                    this.$refs.formLayout.reset();
                }
                this.tipo_reporte_value = null
            },
            redireccionar(){
                this.$router.push('/dashboard');
            },
        },
        created() {
            this.listar();
        },
    };
</script>

<style scoped>
    h2 {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 600;
        line-height: 21.94px;
    }
    .btnAcciones {
        /* width: 135px !important; */
        height: 42px !important;

        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        padding-inline: 12px !important;
    }
    .btnAcciones:hover {
        /* width: 135px !important; */
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }

    th {
        color: #828282;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        padding: 17px 12px 17px 12px;
    }

    .tbl-modal tbody td {
        color: #1e2144;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .contenido {
        /* border-collapse: collapse; */
        max-height: 600px;
        overflow-x: scroll;
        overflow-y: auto;
    }

    .tbl-modal .tbl-header-modal {
        background: #f6f6f6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
        padding: 17px 12px 17px 12px;
    }
    .lastth {
        border-top-right-radius: 20px !important;
        padding: 17px 12px 17px 12px;
    }
    .emptyTableModal {
        color: #c5c5c5 !important;
        font-family: "Montserrat" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 24px !important;
        padding-top: 24px !important;
        text-align: center !important;
    }
</style>
